import React from "react";
import { HashRouter as Router, Redirect, Route } from "react-router-dom";
import "./App.css";
import { Home } from "./pages/Home";
import { WelcomeScreen } from "./pages/WelcomeScreen";
import { Login } from "./pages/Login";
import "./i18n";
import { Signup } from "./pages/Signup";
import { AccountChecking } from "./pages/AccountChecking";
import { SubscriptionRenewal } from "./pages/SubscriptionRenewal";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ForgotPasswordEmail } from "./pages/ForgotPasswordEmail";
import { CookiesProvider } from "react-cookie";
import {
  AuthProvider,
  PrivateRoute,
  PublicRoute,
  RoutesWrapper,
} from "./providers/auth";
import { LoadingProvider } from "./providers/loading";
import { AlertProvider } from "./providers/alert";
import { Onboarding } from "./pages/Onboarding";
import { QueryParamProvider } from "use-query-params";
import { PlanSelection } from "./pages/PlanSelection";
import { StartScreen } from "./pages/StartScreen";
import { Search } from "./pages/Search";
import { Workout } from "./pages/Workout";
import { News } from "./pages/News";
import { Profile } from "./pages/Profile";
import { WorkoutDetail } from "./pages/WorkoutDetail";
import { SearchDetail } from "./pages/SearchDetail";
import { EditProfile } from "./pages/EditProfile";
import { Faq } from "./pages/Faq";
import { YourSubscription } from "./pages/YourSubscription";
import { ResetPassword } from "./pages/ResetPassword";
import { Terms } from "./pages/Terms";
import { SendConfirmationEmail } from "./pages/SendConfirmationEmail";
import { ProfileAvatar } from "./pages/ProfileAvatar";
import { NewsDetail } from "./pages/NewsDetail";
import { ModalNewsProvider } from "./providers/news/ModalNews";
import { Contact } from "./pages/Contact";
import Calendar from "./pages/Calendar";
import InstallAppBanner from "./components/InstallAppBanner";
import { PlanProvider } from "./providers/plan";
import { PayPlan } from "./pages/PayPlan";
import { TutorialAddHome } from "./pages/TutorialAddHome";
import { WorkoutList } from "./pages/WorkoutList";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { OrientationAlert } from "./components/OrientationAlert";
import { Scripts } from "./components/Scripts";
import { Notifications } from "./pages/Notifications";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const environment = process.env.REACT_APP_API_URL.includes("staging")
  ? "staging"
  : "production";

// Sentry.init({
//   dsn: "https://5410e67a8307461397ed1066678cfca2@o136925.ingest.sentry.io/6270522",
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 0.2,
//   environment
// });

export default function App() {
  return (
    <>
      <OrientationAlert />
      <InstallAppBanner>
        <CookiesProvider>
          <LoadingProvider>
            <PayPalScriptProvider
              options={{
                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                "disable-funding": ["mybank", "sofort", "card"],
                vault: true,
                intent: "subscription",
              }}
            >
              <AlertProvider>
                <AuthProvider>
                  <PlanProvider>
                    <Router>
                      <Scripts />
                      <QueryParamProvider ReactRouterRoute={Route}>
                        <ModalNewsProvider>
                          <RoutesWrapper
                            publicRedirect="/welcome-screen"
                            privateRedirect="/"
                          >
                            <PublicRoute
                              path="/welcome-screen"
                              component={WelcomeScreen}
                            />
                            <PublicRoute path="/login" component={Login} />
                            <PublicRoute path="/signup" component={Signup} />
                            <PublicRoute
                              path="/account-checking"
                              component={AccountChecking}
                            />
                            <PublicRoute
                              path="/send-confirmation-email"
                              component={SendConfirmationEmail}
                            />
                            <PublicRoute
                              path="/forgot-password"
                              component={ForgotPassword}
                            />
                            <PublicRoute
                              path="/forgot-password-email"
                              component={ForgotPasswordEmail}
                            />
                            <PublicRoute
                              path="/reset-password"
                              component={ResetPassword}
                            />
                            <PublicRoute
                              path="/plan-selection"
                              component={() => {
                                window.location.href = "https://megafitnesscommunity.com/lista-attesa";
                                return null;
                              }}
                            />
                            <PublicRoute
                              path="/free-trial"
                              component={PlanSelection}
                            />
                            <PublicRoute
                              path="/free-trial7"
                              component={PlanSelection}
                            />
                            <PublicRoute path="/contact" component={Contact} />
                            <PrivateRoute exact path="/" component={Home} />
                            <PrivateRoute
                              path="/subscription-renewal"
                              component={SubscriptionRenewal}
                            />
                            <PrivateRoute
                              path="/pay-plan"
                              component={PayPlan}
                            />
                            <PrivateRoute
                              path="/onboarding"
                              component={Onboarding}
                            />
                            <PrivateRoute
                              path="/start"
                              component={StartScreen}
                            />
                            <PrivateRoute path="/search" component={Search} />
                            <PrivateRoute path="/workout" component={Workout} />
                            <PrivateRoute
                              exact
                              path="/workouts"
                              component={WorkoutList}
                            />
                            <PrivateRoute exact path="/news" component={News} />
                            <PrivateRoute
                              path="/news/:id"
                              component={NewsDetail}
                            />
                            <PrivateRoute path="/profile" component={Profile} />
                            <PrivateRoute
                              path="/profile-avatar"
                              component={ProfileAvatar}
                            />
                            <PrivateRoute
                              path="/workouts/:id"
                              component={WorkoutDetail}
                            />
                            <PrivateRoute
                              path="/search-results"
                              component={SearchDetail}
                            />
                            <PrivateRoute
                              path="/edit-profile"
                              component={EditProfile}
                            />
                            <PrivateRoute path="/faq" component={Faq} />
                            <PrivateRoute
                              path="/subscription"
                              component={YourSubscription}
                            />
                            <PrivateRoute
                              path="/calendar"
                              component={Calendar}
                            />
                            <PrivateRoute
                              path="/notifications"
                              component={Notifications}
                            />
                            <PublicRoute path="/contact" component={Contact} />
                            <Route
                              path="/add-to-home"
                              component={TutorialAddHome}
                            />
                            <Route path="/terms" component={Terms} />
                            <Route component={() => <Redirect />} />
                          </RoutesWrapper>
                        </ModalNewsProvider>
                      </QueryParamProvider>
                    </Router>
                  </PlanProvider>
                </AuthProvider>
              </AlertProvider>
            </PayPalScriptProvider>
          </LoadingProvider>
        </CookiesProvider>
      </InstallAppBanner>
    </>
  );
}
